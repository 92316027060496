<template>
  <div class="messages-container" :data-dragging="isDragging">
    <transition name="slidedown">
      <div v-show="subscriptionPressed || numberOfSelectedThreadsAndBundles > 1" class="mobile-navigation-container">
        <handle-more-subscriptions-mobile />
      </div>
    </transition>
    <div v-show="showToolbar()" class="message-toolbar-container">
      <message-toolbar
        :folder-open="isOpenFolderMenu"
        :notifications="messageNotifications"
        @click.capture="checkPreviewMode"
        @foldersToggle="toggleFolders"
      />
    </div>
    <div v-if="!isPreviewMode" class="messages-overview no-gutters">
      <MessageInboxes
        v-if="canUseInboxFolders"
        v-show="isOpenFolderMenu"
        :folders="folders"
        :common-inboxes="commonInboxes"
        :selected-folder-id="selectedFolderId"
        :selected-common-inbox-id="selectedCommonInboxId"
        :notifications="messageNotifications"
        :collapsed-inbox-ids="collapsedInboxIds"
        :default-width="folderMenuDefaultWidth"
        @selectInbox="selectInbox"
        @selectFolder="selectFolder"
        @folderUpdated="loadFolders"
        @inboxExpanded="handleInboxExpanded"
        @resized="saveFolderMenuDefaultWidth"
        @hidden="hideFolderMenu"
      />
      <AulaResizableContainter
        class="subscription-holder"
        :aria-hidden="isMobile && !isInOverview"
        :default-width="threadViewDefaultWidth"
        :data-view="selectedThreadView"
        :right-resizer="!isMobile"
        @onResized="saveThreadViewDefaultWidth"
      >
        <subscriptions ref="subscriptions" :draggable="isDraggable" @draggingChange="handleDraggingChange" />
        <aula-modal
          ref="infoboxModal"
          header-text="MESSAGE_LEAVE_MODAL_HEADER"
          :show-cancel="false"
          @okClicked="$refs.infoboxModal.hide()"
        >
          {{ 'MESSAGE_BLOCK_COMMUNICATION' | fromTextKey }}
        </aula-modal>
      </AulaResizableContainter>
      <div
        v-show="!isInOverview || !isMobile"
        ref="conversation-scroll"
        class="conversation-holder pr-0"
        :aria-modal="isMobile"
        @scroll="hasBeenScrolledTo()"
      >
        <div v-if="showNewMessage" class="mobile-navigation-container">
          <div
            class="mobile-back-button"
            role="button"
            tabindex="0"
            @click="backToPreviousPage"
            @keydown.enter="backToPreviousPage"
          >
            <i class="icon-Aula_arrow_new_item" />
          </div>
          <div class="title">{{ 'MESSAGE_BAR_TEXT' | fromTextKey }}</div>
        </div>
        <handle-more-subscriptions v-if="numberOfSelectedThreadsAndBundles >= 2" />
        <message-new
          v-else-if="showReplyMessage || (showNewMessage && !shownSubscription)"
          :key="messageNewComponentKey"
          ref="messageNew"
          @showConsentWarning="$refs.modalWarningMediaCannotBeStored.show()"
          @messageSent="loadMsgFromRoute"
        />
        <message-autoreply v-else-if="showAutoReply" />
        <template v-else-if="shownSubscription">
          <shown-subscription :shown-subscription="shownSubscription" @reloadSubscription="loadMsgFromRoute($route)" />
          <Alerts
            v-if="isReminderThread"
            class="reminder-alerts"
            show-no-data
            :related-event-id="shownSubscription.threadEntityLinkDto.entityId"
          >
            <template #no-data>
              <div class="no-invitations-text">{{ noInvitationsText }}</div>
            </template>
          </Alerts>
          <div
            v-if="canWriteMessage"
            class="new-message scrollbar"
            :data-active="showTextarea"
            :class="{ mobile: isMobile }"
          >
            <message-textarea
              ref="messageTextareaRef"
              :shown-subscription="shownSubscription"
              :message="message"
              :is-editing-message="isEditingMessage"
              :edited-message-dto="editedMessageDto"
              :clean-editor="cleanEditor"
              :show-textarea="showTextarea"
              :existing-media="currentMessageExistingMedia"
              @replySent="replySent"
            />
          </div>
          <div v-if="!hasPermissionToWriteMessage" class="new-message p-4 text-center" :class="{ mobile: isMobile }">
            {{ 'MESSAGE_NO_ACCESS_TO_WRITE' | fromTextKey }}
          </div>
        </template>
        <b-spinner v-if="isLoadingSelectedThread && !isMobile && !shownSubscription" class="loading-spinner" />
        <template
          v-if="
            !shownSubscription &&
            hasNotChosenThread &&
            !subscriptionPressed &&
            !showNewMessage &&
            numberOfSelectedThreadsAndBundles < 2 &&
            !isMobile
          "
        >
          <div class="no-choice">
            <i class="icon icon-Aula_write" /><br />
            {{ 'MESSAGE_CHOOSE_MESSAGE' | fromTextKey }}
          </div>
        </template>
      </div>
    </div>
    <media-details v-if="$route.name === 'messageMediaDetails'" parent="messages" :album="selectedMessageAttachments" />
    <aula-modal
      ref="messageDiscardTextareaWarningModal"
      ok-text="YES"
      header-text="MESSAGE_WARNING_HEADER"
      @cancelClicked="$refs.messageDiscardTextareaWarningModal.hide()"
      @okClicked="onConfirmEditMessage"
    >
      {{ 'MESSAGE_DISCARD_WARNING' | fromTextKey }}<br />
      {{ 'MESSAGE_DISCARD_WARNING_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="messageDiscardEditingWarningModal"
      ok-text="YES"
      header-text="MESSAGE_WARNING_HEADER"
      @cancelClicked="onCancelDiscardingClicked"
      @okClicked="onConfirmCancelEditingMessage"
    >
      {{ 'MESSAGE_DISCARD_EDITING_WARNING' | fromTextKey }}<br />
      {{ 'MESSAGE_DISCARD_EDITING_WARNING_2' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import { mapMutations } from 'vuex';
import ShownSubscription from './ShownSubscription.vue';
import MessageNew from './MessageNew.vue';
import AutoReply from './AutoReply.vue';
import Subscriptions from './Subscriptions.vue';
import MessageToolbar from './MessageToolbar.vue';
import HandleMoreSubscriptions from './HandleMoreSubscriptions.vue';
import HandleMoreSubscriptionsMobile from './HandleMoreSubscriptionsMobile.vue';
import MediaDetails from '../gallery/MediaDetail.vue';
import MessageTextarea from './MessageTextarea.vue';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import $ from 'jquery';
import requestCancelTokenMixin from '../../../shared/mixins/requestCancelTokenMixin';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import PollingService from '../../../shared/services/PollingService';
import moment from 'moment-timezone';
import { subscriptionTypes } from '../../../shared/enums/subscriptionTypes';
import { NewMessageSearch } from '../../business/newMessageSearch';
import AulaResizableContainter from '../../../shared/libs/resizable-container/components/AulaResizableContainter.vue';
import { threadViewEnum } from '../../../shared/enums/threadViewEnum';
import { storageKeyTypes } from '../../../shared/enums/storageKeyTypes';
import { folderTypes } from '../../../shared/enums/folderTypes';
import Alerts from '../../../shared/components/Alerts.vue';
import { ThreadType } from '../../../shared/enums/threadType';
import MessageInboxes from './MessageInboxes.vue';
import { MessageFormActionEnum } from './enums/messageFormActionEnum';
import isNumber from 'lodash/isNumber';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import { notificationAreas } from '../../../shared/enums/notificationAreas';
import without from 'lodash/without';
import union from 'lodash/union';
import { notificationEventTypes } from "../../../shared/enums/notificationEventTypes";

export default {
  components: {
    MessageInboxes,
    Alerts,
    AulaResizableContainter,
    'message-new': MessageNew,
    subscriptions: Subscriptions,
    'message-toolbar': MessageToolbar,
    'message-autoreply': AutoReply,
    'handle-more-subscriptions': HandleMoreSubscriptions,
    'handle-more-subscriptions-mobile': HandleMoreSubscriptionsMobile,
    'shown-subscription': ShownSubscription,
    MediaDetails,
    MessageTextarea,
  },
  mixins: [requestCancelTokenMixin],
  provide() {
    return {
      [messageProviderKeyEnum.UPDATE_MESSAGE_NEW_COMPONENT_KEY]: () => (this.messageNewComponentKey += 1),
      [messageProviderKeyEnum.SET_CONVERSATION_LOADING_STATE]: isLoading => (this.isLoadingSelectedThread = isLoading),
      [messageProviderKeyEnum.RESTART_POLLING_SERVICE]: this.restartPollingService,
      [messageProviderKeyEnum.ON_EDIT_MESSAGE]: this.onEditMessage,
      [messageProviderKeyEnum.ON_CANCEL_EDITING_MESSAGE]: this.onCancelEditingMessage,
      [messageProviderKeyEnum.ON_FORWARD_MESSAGE]: this.onForwardMessage,
      [messageProviderKeyEnum.RESET_MESSAGE_TEXTAREA]: this.resetMessageTextarea,
      [messageProviderKeyEnum.SET_CURRENT_MESSAGE]: this.setCurrentMessage,
      [messageProviderKeyEnum.SET_CLEAN_EDITOR]: this.setCleanEditor,
      [messageProviderKeyEnum.SET_SHOW_TEXTAREA]: this.setShowTextarea,
      [messageProviderKeyEnum.ON_SUBSCRIPTION_CLICKED]: this.onSubscriptionClicked,
      [messageProviderKeyEnum.SET_ACTIVE_EDITOR]: editor => (this.activeEditor = editor),
      [messageProviderKeyEnum.IS_DRAFT_SELECTED]: () => this.isDraftSelected,
      [messageProviderKeyEnum.CAN_DISCARD_EDITING_MESSAGE]: () => this.canDiscardEditingMessage,
      [messageProviderKeyEnum.UPDATE_HAS_MESSAGE_CHANGED]: hasMessageChanged =>
        (this.hasMessageChanged = hasMessageChanged),
      [messageProviderKeyEnum.SEARCH_MODEL]: () => this.searchModel,
      [messageProviderKeyEnum.SELECTED_THREAD_VIEW]: () => this.selectedThreadView,
      [messageProviderKeyEnum.SET_SELECTED_THREAD_VIEW]: this.setSelectedThreadView,
      [messageProviderKeyEnum.GET_DELETED_FOLDER_ID]: () => this.deletedFolderId,
      [messageProviderKeyEnum.CAN_MOVE_TO_DELETED_MESSAGE_FOLDER]: () => this.canMoveToDeletedMessageFolder,
      [messageProviderKeyEnum.GET_IS_REMINDER_THREAD]: () => this.isReminderThread,
      [messageProviderKeyEnum.DELETE_OTP_SUBSCRIPTIONS]: (subscriptionsAndBundleThreads, deleteSubscriptions) =>
        this.deleteOtpSubscriptions(subscriptionsAndBundleThreads, deleteSubscriptions),
      [messageProviderKeyEnum.SET_SELECTED_MESSAGE]: this.setSelectedMessage,
      [messageProviderKeyEnum.GET_SELECTED_MESSAGE]: () => this.selectedMessage,
      [messageProviderKeyEnum.SET_MESSAGE_FORM_DATA]: this.setMessageFormData,
      [messageProviderKeyEnum.GET_MESSAGE_FORM_DATA]: () => this.messageFormData,
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.nextRoute = to;
    if (!this.canDiscardEditingMessage) {
      this.$refs.messageDiscardEditingWarningModal.show();
      return false;
    }

    if (to.name === 'messages') {
      this.resetSelectedSubscription();
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.nextRoute = to;
    if (!this.canDiscardEditingMessage) {
      this.$refs.messageDiscardEditingWarningModal.show();
      return false;
    }
    next();
  },
  data: function () {
    return {
      hasNotChosenThread: true,
      showNewMessage: false,
      showReplyMessage: false,
      showAutoReply: false,
      title: 'Beskeder',
      messageOwnerTypes: messageOwnerTypes,
      openMessageCancelTokenSource: null,
      messageNewComponentKey: 0,
      isLoadingSelectedThread: false,
      messagePollingService: null,
      lastPollingTimestamp: moment().format(),
      isEditingMessage: false,
      hasMessageChanged: false,
      editedMessageDto: null,
      editedMessageContent: '',
      currentMessageExistingMedia: [],
      cleanEditor: false,
      showTextarea: false,
      nextRoute: null,
      activeEditor: null,
      discardEditingCallback: null,
      discardEditingCallbackParams: [],
      selectedThreadView: threadViewEnum.STANDARD,
      threadViewEnum,
      searchModel: new NewMessageSearch({
        content: '',
        filter: '',
        fromDate: null,
        toDate: null,
        query: '',
        subject: '',
        hasAttachments: false,
        participants: [],
        threadCreators: [],
        offset: 0,
      }),
      selectedMessage: null,
      messageFormData: null,
      isOpenFolderMenu: false,
      isDragging: false,
      collapsedInboxIds: [],
    };
  },
  computed: {
    ...mapGetters({
      shownSubscriptionId: types.MESSAGES_GET_SELECTED_SUBSCRIPTION_ID,
      selectedDraft: types.MESSAGES_GET_SELECTED_DRAFT,
      selectedThreadIds: types.MESSAGES_GET_CHOSEN_THREAD_IDS,
      selectedBundleIds: types.MESSAGES_GET_CHOSEN_BUNDLE_IDS,
      subscriptions: types.MESSAGES_GET_SUBSCRIPTIONS,
      isMobile: types.GET_IS_MOBILE,
      subscriptionPressed: types.MESSAGES_GET_SUBSCRIPTIONS_ONPRESS_MOBILEVIEW,
      profile: types.GET_CURRENT_PROFILE,
      hasPermission: types.HAS_PERMISSION,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      moreMessagesExist: types.MESSAGES_GET_MORE_MESSAGES_EXITS,
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      selectedSubscription: types.MESSAGES_GET_SELECTED_SUBSCRIPTION,
      singleThread: types.MESSAGES_GET_SINGLE_THREAD,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      isLoadingThreads: types.IS_LOADING_THREADS,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
      activeChildrenIds: types.GET_ACTIVE_CHILDREN,
      hasNewThreads: types.MESSAGES_HAS_NEW_THREADS,
      hasNewMessages: types.MESSAGES_HAS_NEW_MESSAGES,
      currentDraftMessage: types.MESSAGES_GET_CURRENT_DRAFT_MESSAGE,
      commonInboxFolders: types.MESSAGES_GET_FOLDERS_FOR_CURRENT_COMMON_INBOX,
      commonInboxes: types.MESSAGES_GET_COMMON_INBOXES,
      folders: types.MESSAGES_GET_FOLDERS,
      institutions: types.GET_INSTITUTIONS,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      selectedBundleId: types.MESSAGES_SELECTED_BUNDLE_ID,
      notifications: types.GET_NOTIFICATIONS,
      otpInboxes: types.MESSAGES_GET_OTP_INBOXES,
    }),
    isDraggable() {
      const isMultiselect = this.numberOfSelectedThreadsAndBundles > 0;
      return this.isOpenFolderMenu && !isMultiselect;
    },
    canUseInboxFolders() {
      return this.hasPermission(permissionEnum.INBOX_FOLDERS);
    },
    messageNotifications() {
      return this.notifications.filter(
        notification =>
          notification.notificationType === notificationTypes.BADGE &&
          notification.notificationArea === notificationAreas.MESSAGES
      );
    },
    selectedFolderId() {
      if (!isNumber(this.chosenFolderAndMailOwner.folderId)) {
        return null;
      }
      return this.chosenFolderAndMailOwner.folderId;
    },
    selectedCommonInboxId() {
      return this.chosenFolderAndMailOwner.mailOwnerId;
    },
    noInvitationsText() {
      const isThreadCreator = this.shownSubscription.threadCreator?.mailBoxOwner.profileId === this.profile.profileId;
      if (this.shownSubscription.threadEntityLinkDto.threadType === ThreadType.VACATION_REQUEST_REMINDER) {
        if (isThreadCreator) {
          return this.$options.filters.fromTextKey('MESSAGE_VACATION_REQUEST_ADDED');
        }
        return this.$options.filters.fromTextKey('MESSAGE_VACATION_REQUEST_NOT_AVAILABLE');
      }
      if (isThreadCreator) {
        return this.$options.filters.fromTextKey('MESSAGE_INVITATIONS_ADDED');
      }
      return this.$options.filters.fromTextKey('MESSAGE_INVITATIONS_NOT_AVAILABLE');
    },
    canMoveToDeletedMessageFolder() {
      if (!this.hasPermission(permissionEnum.INBOX_FOLDERS)) {
        return false;
      }
      return this.chosenFolderAndMailOwner?.folderType === folderTypes.NORMAL;
    },
    deletedFolderId() {
      let deletedFolderId = this.folders.find(folder => folder.type === folderTypes.DELETED)?.id;
      if (this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX) {
        deletedFolderId = this.commonInboxFolders.find(folder => folder.type === folderTypes.DELETED)?.id;
      }
      return deletedFolderId;
    },
    message: {
      get() {
        if (this.isEditingMessage) {
          return this.editedMessageContent;
        }
        return this.currentDraftMessage;
      },
      set(value) {
        if (this.isEditingMessage) {
          this.editedMessageContent = value;
          return;
        }
        this.updateCurrentDraftMessage(value);
      },
    },
    selectedViewWidth() {
      if (this.selectedThreadView === threadViewEnum.COMPACT) {
        return '40%';
      }
      return '33%';
    },
    threadViewDefaultWidth() {
      if (this.isMobile) {
        return '100%';
      }

      return this.AulaStorage.getItem(
        `${this.profile.profileId}${storageKeyTypes.messageThreadViewDefaultWidth}`,
        { encryption: this.profile.encryptionKey },
        this.selectedViewWidth
      );
    },
    folderMenuDefaultWidth() {
      if (this.isMobile) {
        return '100%';
      }

      return this.AulaStorage.getItem(
        `${this.profile.profileId}${storageKeyTypes.messageFolderMenuDefaultWidth}`,
        { encryption: this.profile.encryptionKey },
        '280px'
      );
    },
    isDraftSelected() {
      return !!this.$route?.params.id?.toString().match(/^DRAFT/);
    },
    canDiscardEditingMessage() {
      return !this.isEditingMessage || !this.hasMessageChanged;
    },
    numberOfSelectedThreadsAndBundles() {
      return this.selectedThreadIds.length + this.selectedBundleIds.length;
    },
    shownSubscription() {
      if (this.shownSubscriptionId === -1 && !this.$route.params.id) {
        return null;
      } else if (this.shownSubscriptionId == this.$route.params.id) {
        return this.selectedSubscription;
      }
      return null;
    },
    canWriteMessage() {
      if (this.isReminderThread && !this.isEditingMessage) {
        return false;
      }
      return this.hasPermissionToWriteMessage;
    },
    hasPermissionToWriteMessage() {
      return this.hasPermissionOnInstitution(permissionEnum.WRITE_MESSAGE, this.shownSubscription?.institutionCode);
    },
    isReminderThread() {
      return [ThreadType.EVENT_REMINDER, ThreadType.VACATION_REQUEST_REMINDER].includes(
        this.shownSubscription.threadEntityLinkDto.threadType
      );
    },
    selectedMessageAttachments() {
      if (
        this.$route.params.mediaId != null &&
        this.selectedSubscription != null &&
        this.selectedSubscription.messages != null
      ) {
        let attachments = [];
        for (const message of this.selectedSubscription.messages) {
          if (message.attachments != null) {
            attachments = attachments.concat(message.attachments);
          }
        }
        return attachments.filter(att => att.media != null).map(att => att.media);
      } else {
        return [];
      }
    },
    isInOverview() {
      return this.$route.name === 'messages';
    },
    commonInboxId() {
      return this.shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
        ? this.shownSubscription.mailBoxOwner.id
        : null;
    },
    otpInboxId() {
      return this.shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX
        ? this.shownSubscription.mailBoxOwner.id
        : null;
    },
  },
  watch: {
    $route(to) {
      this.hasNotChosenThread = false;
      this.showNewMessage = this.newMessageRoute(to.path);
      this.showReplyMessage = this.newMessageRoute(to.path);
      this.showAutoReply = this.autoReplyRoute(to.path);
      setTimeout(() => {
        if (!(this.showNewMessage || this.showReplyMessage || this.showAutoReply || to.path.split('/').length > 2)) {
          this.hasNotChosenThread = true;
        }
      }, 1000);
      if (this.isMobile) {
        if (this.showNewMessage || this.showReplyMessage || this.showAutoReply || to.name === 'uniqueThread') {
          document.getElementById('aula-header')?.setAttribute('aria-hidden', 'true');
          document.getElementById('aula-menu')?.setAttribute('aria-hidden', 'true');
        } else {
          document.getElementById('aula-header')?.setAttribute('aria-hidden', 'false');
          document.getElementById('aula-menu')?.setAttribute('aria-hidden', 'false');
        }
      }
      if (this.$route.name !== 'uniqueThread') {
        this.setSelectedBundleId(null);
      }
    },
    isProfileLoaded(inited) {
      if (inited) {
        this.checkHasPermissionToWriteMessage();
      }
    },
  },
  mounted() {
    this.messagePollingService = new PollingService(this.pollNewThreads);
    // Load draft messages from local storage
    this.mutateDraftMessagesFromLocalStorage();

    this.initializeFolderList();
    this.initializeThreads();
    this.loadMsgFromRoute();
    this.restartPollingService();
  },
  created() {
    if (this.profile.role === portalRoles.EMPLOYEE) {
      if (this.hasPermission(permissionEnum.INBOX_SET_PERSONAL_AUTOREPLY)) {
        this.$store.dispatch('messages/INIT_AUTOREPLY');
      }
    }

    this.showNewMessage = this.newMessageRoute(this.$route.path);
    this.showReplyMessage = this.newMessageRoute(this.$route.path);
    this.showAutoReply = this.autoReplyRoute(this.$route.path);

    // reset search
    this.setInSearchState(false);
    this.initSavedViews();
  },
  beforeDestroy() {
    this.messagePollingService.stop();
    this.messagePollingService = null;
    this.resetThreadBundles();
    this.resetShownSubscription();
  },
  methods: {
    ...mapActions({
      openMessage: types.SELECT_SUBSCRIPTION,
      setMailOwner: types.SELECT_MAIL_OWNER,
      resetSelectedSubscription: types.RESET_SELECT_SUBSCRIPTION,
      loadSingleThread: types.LOAD_SINGLE_THREAD,
      resetThreadBundles: types.RESET_THREAD_BUNDLES,
      getNewThreads: types.ACTION_GET_NEW_THREADS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
      resetCurrentDraftMessage: types.MESSAGES_EMPTY_CURRENT_DRAFT_MESSAGE,
      saveDraftMessage: types.MESSAGES_SAVE_DRAFT_MESSAGE,
      loadInboxFolders: types.INIT_THREADS_FOLDERS,
      loadCommonInboxFolders: types.LOAD_COMMON_INBOX_FOLDERS,
      loadAllCommonInboxes: types.LOAD_ALL_COMMON_INBOXES,
      loadThreadsInBundleById: types.LOAD_THREADS_IN_BUNDLE_BY_ID,
      updateThreadViewAction: types.UPDATE_THREADS_VIEW,
      loadOtpInboxes: types.ACTION_GET_OTP_INBOXES,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
    }),
    ...mapMutations({
      resetSelectedDraft: types.MUTATE_RESET_SELECTED_DRAFT,
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      setMessageSearchQuery: types.MUTATE_SUBSCRIPTIONS_SEARCH_QUERY,
      setInSearchState: types.MUTATE_SUBSCRIPTIONS_IN_SEARCH,
      mutateDraftMessagesFromLocalStorage: types.MUTATE_DRAFT_MESSAGES_FROM_LOCAL_STORAGE,
      setSelectedBundleId: types.MUTATE_SELECTED_BUNDLE_ID,
      resetShownSubscription: types.MUTATE_RESET_SHOWN_SUBSCRIPTION,
      updateCurrentDraftMessage: types.MUTATE_UPDATE_CURRENT_DRAFT_MESSAGE,
      setChosenFolderAndMailOwner: types.MUTATE_CHOSEN_FOLDER_AND_MAIL_OWNER,
    }),
    async initializeThreads() {
      if (this.profile.role === portalRoles.OTP) {
        await this.loadOtpInboxes();
      }
      this.fetchThreads();
    },
    initSavedViews() {
      const fallbackThreadView = threadViewEnum.STANDARD;
      const fallbackFolderMenuState = Boolean(!this.isMobile);
      const fallbackCollapsedInboxIds = [];

      this.selectedThreadView = this.AulaStorage.getItem(
        `${this.profile.profileId}${storageKeyTypes.messageThreadView}`,
        { encryption: this.profile.encryptionKey },
        fallbackThreadView
      );
      this.isOpenFolderMenu = this.AulaStorage.getItem(
        `${this.profile.profileId}${storageKeyTypes.messageFolderMenuVisible}`,
        { encryption: this.profile.encryptionKey },
        fallbackFolderMenuState
      );
      this.collapsedInboxIds = this.AulaStorage.getItem(
        `${this.profile.profileId}${storageKeyTypes.messageCollapsedInboxes}`,
        { encryption: this.profile.encryptionKey },
        fallbackCollapsedInboxIds
      );
    },
    setDefaultMailBox() {
      const chosenInboxFolder = this.AulaStorage.getItem(
        `${this.profile.profileId}${storageKeyTypes.messageSelectedFolder}`,
        { encryption: this.profile.encryptionKey },
        this.chosenFolderAndMailOwner
      );
      const selectedInbox = this.commonInboxes.find(inbox => inbox.commonInboxId === chosenInboxFolder.mailBoxId);

      if (this.activeInstitutionCodes.includes(selectedInbox?.institutionCode)) {
        this.setChosenFolderAndMailOwner(chosenInboxFolder);
      }
    },
    initializeFolderList() {
      if (!this.hasPermission(permissionEnum.INBOX_FOLDERS)) {
        return;
      }
      this.loadAllCommonInboxes();
      this.loadInboxFolders();
      this.setDefaultMailBox();
    },
    loadFolders({ commonInboxId }) {
      if (commonInboxId) {
        this.loadCommonInboxFolders({ commonInboxId });
      } else {
        this.loadInboxFolders();
      }
    },
    handleDraggingChange(isDragging) {
      this.isDragging = isDragging;
    },
    selectFolder({ folder, commonInboxId }) {
      const mailOwnerId = commonInboxId || null;
      const mailOwnerType = commonInboxId ? messageOwnerTypes.COMMON_INBOX : messageOwnerTypes.INSTITUTION_PROFILE;
      const commonInboxName = folder.commonInboxName || null;
      this.initializeMailBox({
        mailOwnerId,
        mailOwnerType,
        commonInboxName,
        folderId: folder.id,
        folderName: folder.name,
        folderType: folder.type,
      });
      if (this.isMobile) {
        this.toggleFolders();
      }
    },
    selectInbox({ commonInbox }) {
      const mailOwnerId = commonInbox?.id || null;
      const mailOwnerType = commonInbox?.id ? messageOwnerTypes.COMMON_INBOX : messageOwnerTypes.INSTITUTION_PROFILE;
      const commonInboxName = commonInbox?.name || null;
      const folderName = commonInbox?.name || null;
      this.initializeMailBox({
        mailOwnerId,
        mailOwnerType,
        commonInboxName,
        folderId: null,
        folderName: folderName,
        folderType: folderTypes.NORMAL,
      });
      if (this.isMobile) {
        this.toggleFolders();
      }
    },
    initializeMailBox({ mailOwnerId, mailOwnerType, commonInboxName, folderId, folderName, folderType }) {
      const chosenFolderAndMailOwner = this.chosenFolderAndMailOwner;
      chosenFolderAndMailOwner.mailOwnerId = mailOwnerId;
      chosenFolderAndMailOwner.mailOwnerType = mailOwnerType;
      chosenFolderAndMailOwner.commonInboxName = commonInboxName;
      chosenFolderAndMailOwner.folderId = folderId;
      chosenFolderAndMailOwner.folderName = folderName;
      chosenFolderAndMailOwner.folderType = folderType;
      this.setChosenFolderAndMailOwner(chosenFolderAndMailOwner);
      this.storeSelectedInboxFolder();
      this.clearSearch();
      this.fetchThreads();
      this.deleteNewInboxNotification(mailOwnerId);
    },
    storeSelectedInboxFolder() {
      this.AulaStorage.setItem(
        `${this.profile.profileId}${storageKeyTypes.messageSelectedFolder}`,
        { encryption: this.profile.encryptionKey },
        this.chosenFolderAndMailOwner
      );
    },
    fetchThreads() {
      this.updateThreadViewAction({
        folderId: this.chosenFolderAndMailOwner.folderId,
        filterType: this.chosenFolderAndMailOwner.filter,
        mailOwnerId: this.chosenFolderAndMailOwner.mailOwnerId,
        sort: this.chosenFolderAndMailOwner.sort,
        order: this.chosenFolderAndMailOwner.order,
      });
    },
    toggleFolders() {
      this.isOpenFolderMenu = !this.isOpenFolderMenu;
      this.AulaStorage.setItem(
        `${this.profile.profileId}${storageKeyTypes.messageFolderMenuVisible}`,
        { encryption: this.profile.encryptionKey },
        this.isOpenFolderMenu
      );
    },
    hideFolderMenu() {
      this.isOpenFolderMenu = false;
      this.AulaStorage.setItem(
        `${this.profile.profileId}${storageKeyTypes.messageFolderMenuVisible}`,
        { encryption: this.profile.encryptionKey },
        false
      );
    },
    handleInboxExpanded(commonInboxId, isExpanded) {
      if (isExpanded) {
        this.collapsedInboxIds = without(this.collapsedInboxIds, commonInboxId);
      } else {
        this.collapsedInboxIds = union(this.collapsedInboxIds, [commonInboxId]);
      }
      this.AulaStorage.setItem(
        `${this.profile.profileId}${storageKeyTypes.messageCollapsedInboxes}`,
        { encryption: this.profile.encryptionKey },
        this.collapsedInboxIds
      );
    },
    setSelectedMessage(message) {
      this.selectedMessage = message;
    },
    setMessageFormData(data) {
      this.messageFormData = data;
    },
    setSelectedThreadView(threadView) {
      this.AulaStorage.setItem(
        `${this.profile.profileId}${storageKeyTypes.messageThreadView}`,
        { encryption: this.profile.encryptionKey },
        threadView
      );
      this.selectedThreadView = threadView;
      this.saveThreadViewDefaultWidth(this.selectedViewWidth);
    },
    saveThreadViewDefaultWidth(width) {
      this.AulaStorage.setItem(
        `${this.profile.profileId}${storageKeyTypes.messageThreadViewDefaultWidth}`,
        { encryption: this.profile.encryptionKey },
        width
      );
    },
    saveFolderMenuDefaultWidth(width) {
      this.AulaStorage.setItem(
        `${this.profile.profileId}${storageKeyTypes.messageFolderMenuDefaultWidth}`,
        { encryption: this.profile.encryptionKey },
        width
      );
    },
    onSubscriptionClicked(subscription, subscriptionQuery = {}) {
      const isThreadChanged = subscription.id !== this.$route.params.id;
      const canChangeThreadView =
        subscription.subscriptionType !== subscriptionTypes.BUNDLE &&
        isThreadChanged &&
        this.numberOfSelectedThreadsAndBundles === 0;
      if (!canChangeThreadView) {
        return;
      }

      if (subscription.subscriptionType === subscriptionTypes.UNBUNDLED) {
        this.setSelectedBundleId(null);
      }

      this.$router.push({
        name: 'uniqueThread',
        params: { id: subscription.id, subscriptionId: subscription.subscriptionId },
        query: subscriptionQuery,
      });
    },
    setCurrentMessage(value) {
      this.hasMessageChanged = true;
      this.message = value;
    },
    onConfirmEditMessage() {
      this.$refs.messageDiscardTextareaWarningModal.hide();
      this.populateMessageTextarea();
    },
    onConfirmCancelEditingMessage() {
      this.$refs.messageDiscardEditingWarningModal.hide();
      this.resetMessageTextarea();
      if (this.nextRoute) {
        this.$router.push(this.nextRoute);
      }
      if (this.discardEditingCallback) {
        this.discardEditingCallback(...this.discardEditingCallbackParams);
      }
    },
    onCancelDiscardingClicked() {
      this.$refs.messageDiscardEditingWarningModal.hide();
      this.nextRoute = null;
      this.discardEditingCallback = null;
      this.discardEditingCallbackParams = null;
    },
    onEditMessage(messageDto) {
      this.editedMessageDto = messageDto;
      if ((this.message && !this.isEditingMessage) || !this.canDiscardEditingMessage) {
        this.$refs.messageDiscardTextareaWarningModal.show();
        return;
      }
      this.populateMessageTextarea();
    },
    onForwardMessage(message) {
      this.setSelectedMessage(message);
      this.setMessageFormData({
        institutionCode: this.shownSubscription.institutionCode,
        threadId: this.shownSubscription.id,
        messageId: message.id,
        commonInboxId: this.commonInboxId,
        otpInboxId: this.otpInboxId,
        action: MessageFormActionEnum.FORWARD_MESSAGE,
      });
      this.$router.push({
        name: 'newMessage',
      });
    },
    populateMessageTextarea() {
      this.isEditingMessage = true;
      this.showTextarea = true;
      this.message = this.editedMessageDto.text.html;
      this.hasMessageChanged = false;
      this.resetCurrentDraftMessage();
      this.saveDraftMessage({
        id: this.shownSubscription.id,
        navLocal: this.$route.name === 'uniqueThread',
      });
      this.activeEditor?.setContent(this.editedMessageDto.text.html);

      this.currentMessageExistingMedia = [];
      if (Array.isArray(this.editedMessageDto.attachments)) {
        this.currentMessageExistingMedia.push(...this.editedMessageDto.attachments);
      }
    },
    onCancelEditingMessage(callback = null, ...callbackParams) {
      if (this.canDiscardEditingMessage) {
        this.message = '';
        this.resetMessageTextarea();
      } else {
        this.discardEditingCallback = callback;
        this.discardEditingCallbackParams = callbackParams;
        this.$refs.messageDiscardEditingWarningModal.show();
      }
    },
    resetMessageTextarea() {
      this.editedMessageContent = '';
      this.editedMessageDto = null;
      this.isEditingMessage = false;
      this.cleanEditor = true;
      this.showTextarea = false;
      this.currentMessageExistingMedia = [];
      this.hasMessageChanged = false;
    },
    setCleanEditor(value) {
      this.cleanEditor = value;
    },
    setShowTextarea(value) {
      this.showTextarea = value;
    },
    pollNewThreads() {
      const promise = this.getNewThreads(this.lastPollingTimestamp).then(() => {
        this.handleNewMessageData();
      });
      this.lastPollingTimestamp = moment().format();
      return promise;
    },
    restartPollingService() {
      if (!this.messagePollingService) {
        return;
      }
      this.messagePollingService.stop();
      this.messagePollingService.start();
    },
    handleNewMessageData() {
      if (!this.hasNewThreads) {
        return;
      }
      this.messagePollingService.reset();
      if (!this.hasNewMessages) {
        this.loadNotifications({
          activeChildrenIds: this.activeChildrenIds,
          activeInstitutionCodes: this.activeInstitutionCodes,
        });
      }
    },
    checkPreviewMode(event) {
      if (this.isPreviewMode) {
        event.preventDefault();
      }
    },
    checkHasPermissionToWriteMessage() {
      if (!this.hasPermission(permissionEnum.WRITE_MESSAGE)) {
        this.$refs.infoboxModal.show();
      }
    },
    scrollMessagesToBottom() {
      const el = $('.threads-container');
      el.scrollTop = el.scrollHeight;
      if (window.innerWidth < 450) {
        if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
          window.scrollTo(0, el.scrollHeight - 150);
        } else {
          setTimeout(function () {
            $('html').animate({ scrollTop: el.scrollHeight - 150 });
          }, 500);
        }
      }
    },
    replySent(hasSecureDocuments) {
      this.scrollMessagesToBottom();
      if (hasSecureDocuments) {
        this.loadMsgFromRoute();
      }
    },
    backToPreviousPage() {
      if (this.$route.path.includes('videresend')) {
        this.$router.push({
          name: 'uniqueThread',
          params: { id: this.$route.params.subscriptionId },
        });
      } else if (this.$route.path.includes('beskeder') || this.$route.path.includes('opret')) {
        this.$router.push({ name: 'messages' });
      }
    },
    newMessageRoute(path) {
      if (path.startsWith('/beskeder/opret')) {
        this.resetSelectedSubscription();
        this.resetSelectedDraft();
        return true;
      } else if (path.match(/DRAFT[0-9]+$/)) {
        this.messageNewComponentKey++;
        return true;
      }
      return false;
    },
    autoReplyRoute(path) {
      return path === '/beskeder/autosvar';
    },
    loadMsgFromRoute(route = this.$route) {
      if (!route.params.id) {
        return;
      }

      if (route.name === 'messages') {
        this.resetSelectedSubscription();
        this.resetSelectedDraft();
      }

      const mailBoxOwner = {
        mailOwnerType: this.chosenFolderAndMailOwner.mailOwnerType,
        id: this.chosenFolderAndMailOwner.mailOwnerId,
      };

      if (this.selectedBundleId) {
        this.loadThreadsInBundleById({ bundleId: this.selectedBundleId });
      }

      if (!this.isDraftSelected) {
        this.loadSingleThread({
          threadId: route.params.id,
          mailBoxOwnerType: route.query.mailBoxOwnerType,
          mailOwnerId: route.query.mailOwnerId,
        }).then(() => {
          if (this.singleThread != null && this.singleThread.sensitive && !this.isSteppedUp) {
            this.setStepUpNotification({
              showStepUpNotification: true,
              redirectedUrl: window.location.href,
              parent: parentTypes.MESSAGES,
              cancelUrl: '/beskeder',
            });
          } else {
            this.cancelPreviousOpenMessageRequest();
            this.generateOpenMessageCancelTokenSource();
            this.hasNotChosenThread = false;
            const payload = {
              subscriptionId: route.params.id,
              cancelToken: this.openMessageCancelTokenSource.token,
            };
            if (route.query.fromSearch) {
              payload.fromSearch = true;
            }

            if (route.query.mailOwnerId != null) {
              payload.mailOwnerId = route.query.mailOwnerId;
            } else {
              if (mailBoxOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX) {
                payload.mailOwnerId = mailBoxOwner.id;
              }
            }

            if (route.query.folderId != null) {
              payload.folderId = route.query.folderId;
            }

            if (route.query.otpInboxId != null) {
              payload.otpInboxId = route.query.otpInboxId;
            } else {
              if (mailBoxOwner.mailOwnerType === messageOwnerTypes.OTP_INBOX) {
                payload.otpInboxId = mailBoxOwner.id;
              }
            }

            const chosenSubscription = this.subscriptions.find(sub => sub.id == route.params.id);
            if (chosenSubscription && chosenSubscription.institutionCode) {
              payload.institutionCode = chosenSubscription.institutionCode;
            }

            this.openMessage(payload).then(
              () => {
                this.resetOpenMessageCancelTokenSource();
              },
              () => null
            );
          }
        });
      } else {
        this.cancelPreviousOpenMessageRequest();
        this.generateOpenMessageCancelTokenSource();
        const payload = {
          subscriptionId: route.params.id,
          subscriptionLeft: false,
          mailOwnerId: null,
          cancelToken: this.openMessageCancelTokenSource.token,
        };
        this.openMessage(payload).then(
          () => {
            this.resetOpenMessageCancelTokenSource();
          },
          () => null
        );
      }
    },
    showToolbar() {
      if (this.subscriptionPressed) {
        return false;
      }
      return (
        !this.showNewMessage &&
        this.shownSubscription === null &&
        this.numberOfSelectedThreadsAndBundles < 2 &&
        !this.showAutoReply
      );
    },
    hasBeenScrolledTo() {
      this.$root.$emit('scrolledTo', this.$refs['conversation-scroll']);
    },
    clearSearch() {
      this.setMessageSearchQuery('');
      this.$refs.subscriptions.clearAllInput();
    },
    cancelPreviousOpenMessageRequest() {
      this.cancelAxiosRequest(this.openMessageCancelTokenSource);
    },
    generateOpenMessageCancelTokenSource() {
      this.openMessageCancelTokenSource = this.getAxiosCancelTokenSource();
    },
    resetOpenMessageCancelTokenSource() {
      this.openMessageCancelTokenSource = null;
    },
    groupThreadsByMailBoxOwnerId(subscriptionsAndBundleThreads) {
      return subscriptionsAndBundleThreads
        .filter(thread => this.selectedThreadIds.some(threadId => threadId === thread.id))
        .reduce((acc, thread) => {
          const mailBoxOwnerId = thread.mailBoxOwner.id;
          const group = acc[mailBoxOwnerId] ? acc[mailBoxOwnerId] : [];
          return { ...acc, [mailBoxOwnerId]: [...group, thread.id] };
        }, {});
    },
    async deleteOtpSubscriptions(subscriptionsAndBundleThreads, deleteSubscriptions) {
      const isDeletedSubscriptionShown = this.selectedThreadIds.some(
        threadId => threadId == this.shownSubscription?.subscriptionId
      );
      if (isDeletedSubscriptionShown) {
        this.resetShownSubscription();
      }

      const threadsGroupedByMailBoxOwnerId = this.groupThreadsByMailBoxOwnerId(subscriptionsAndBundleThreads);
      Object.keys(threadsGroupedByMailBoxOwnerId).map(mailBoxOwnerId =>
        deleteSubscriptions({
          otpInboxId: mailBoxOwnerId,
          threadIds: threadsGroupedByMailBoxOwnerId[mailBoxOwnerId],
          subscriptionIds: [],
        })
      );
    },
    deleteNewInboxNotification(commonInboxId) {
      const notifications = this.notifications.filter(
        notification => notification.commonInboxId == commonInboxId &&
          notification.notificationEventType === notificationEventTypes.ADDED_TO_COMMON_INBOX
      );
      if (notifications.length > 0) {
        this.deleteNotifications({
          notifications: notifications.map(notification => ({
            notificationId: notification.notificationId,
            institutionProfileId: notification.institutionProfileId,
          })),
        });
      }
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/scrollbar.scss';

.inbox-menu-container {
  min-width: 200px;
  max-width: 400px;
  flex-shrink: 0;

  @include breakpoint-lg-down() {
    min-width: 100%;
    max-width: unset;
  }
}

.subscription-holder {
  --resizer-color: var(--color-grey-base);

  position: relative;
  max-width: 100%;
  @include breakpoint-lg() {
    min-width: var(--subscription-min-width, unset);

    &.wide {
      flex-basis: 40%;
    }
  }

  &[data-view='standard'] {
    --subscription-min-width: var(--subscription-min-width-standard);
  }

  &[data-view='compact'] {
    --subscription-min-width: var(--subscription-min-width-compact);
  }
}

.loading-spinner {
  width: 40px;
  height: 40px;
  display: flex;
  margin: auto;
}

.reminder-alerts {
  padding: 15px 20px;
}

.messages-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100vh - var(--menu-top-bar-height));

  @include breakpoint-lg-down() {
    height: calc(100svh - var(--menu-top-bar-height));
  }

  &[data-dragging] {
    /deep/ .message-inbox:not([data-selected]) {
      border-radius: 4px;

      .inbox-button {
        color: var(--color-grey);

        &:hover {
          outline: none;
          cursor: no-drop;
        }
      }
    }

    /deep/ .inbox-button:hover {
      background-color: var(--color-grey-dark);
      outline: 1px solid var(--color-primary-dark);
      cursor: alias;
    }
  }

  .threads-container {
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: auto;
    overflow-y: auto;
  }

  /deep/ .resizer {
    --resizer-z-index: 999;
  }

  .new-message {
    background-color: $color-grey-base;
    padding-left: 1rem;
    padding-right: 1rem;
    max-height: 50vh;

    &[data-active] {
      overflow-y: auto;
    }
  }
}

.messages-container {
  .aula-modal-open & {
    /deep/ .subscription-holder,
    /deep/ .b-nav-dropdown a {
      z-index: initial !important;
    }
  }
}

.messages-overview {
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  min-height: 0;
  background-color: var(--color-grey-light);

  @include breakpoint-lg-down() {
    overflow: hidden;
  }

  .conversation-holder {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: var(--conversation-min-width);
    width: 0;

    @include breakpoint-lg-down {
      position: fixed;
      inset: 0;
      z-index: var(--modal-z-index);
      background: #f6f7f8;
      width: auto;
      min-width: auto;
    }
  }
}

.message-toolbar-container {
  @include breakpoint-lg() {
    display: block !important;
  }
}

.mobile-navigation-container {
  height: 60px;
  min-height: 60px;
  background-color: var(--color-primary-darker);

  @include breakpoint-lg() {
    display: none;
  }

  .title {
    color: var(--color-white);
    width: 100%;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-back-button {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 50px;
    z-index: 1037;

    .icon-Aula_arrow_new_item {
      color: $color-white;
      font-weight: 900;
      font-size: 28px;
      display: inline-block;
      line-height: 60px;
      vertical-align: top;
      float: left;
      margin: 0 -48px 0 0px;
      padding: 0px 21px;

      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
    }
  }
}

.slidedown-enter-active,
.slidedown-leave-active,
.slidedown-enter,
.slidedown-leave-to,
.slidedown-enter-active > div,
.slidedown-leave-active > div {
  transition: 0.5s;
}

.slidedown-enter,
.slidedown-leave-to {
  > div {
    top: -115px;
  }
}

.no-choice {
  width: 100%;
  min-height: 200px;
  text-align: center;
  position: relative;
  color: $color-grey;
  font-size: 24px;
  top: calc(50vh - 140px);

  .icon {
    font-size: 40px;
  }
}

.no-invitations-text {
  display: flex;
  justify-content: center;
  font-weight: bold;
  text-align: center;
}
</style>
